import InputError from '@/Components/Elements/Inputs/InputError';
import TextInput from '@/Components/Elements/Inputs/TextInput';
import Aid from '@/Helpers/Aid';
import GuestLayout from '@/Layouts/GuestLayout';
import { Head, Link, useForm } from '@inertiajs/react';

import React, { useEffect } from 'react';

// eslint-disable-next-line react/prop-types
export default function Login({ status, canResetPassword }) {
  const { data, setData, post, processing, errors, reset } = useForm({
    email: '',
    password: '',
    remember: '',
  });

  useEffect(() => {
    return () => {
      reset('password');
    };
    /* eslint-disable */
  }, []);

  const handleOnChange = event => {
    setData(
      event.target.name,
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
    );
  };

  const submit = e => {
    e.preventDefault();

    post(window.route('login'));
  };

  return (
    <GuestLayout>
      <Head title="OCSEO Log in" />

      <div className="login-box">
        <div className={'grid gap-30px'}>
          <div className="flex justify-center items-center">
            <Link href="/">
              <img
                src={Aid.asset('img/logo-1024.svg')}
                alt="OCSEO Logo"
                className="w-[300px]"
              />
            </Link>
          </div>

          <div className="grid gap-20px">
            <h1 className="text-center font-semibold text-3xl tracking-wide">
              SEO Marketing Portal
            </h1>
            <div className={'flex gap-2 justify-center items-center'}>
              <span>No Account?</span>
              <span className={'underline'}>View SEO Programs</span>
            </div>

            {status && (
              <div className="font-medium text-sm text-green-600">{status}</div>
            )}

            <form className={'grid gap-20px'} onSubmit={submit}>
              <div className={'grid gap-2'}>
                <TextInput
                  id="email"
                  type="email"
                  name="email"
                  value={data.email}
                  className={'w-full ' + (errors.email && 'error')}
                  autoComplete="username"
                  isFocused={true}
                  onChange={handleOnChange}
                  placeholder="Email"
                />

                <InputError message={errors.email} />
              </div>

              <div className="grid gap-2">
                <TextInput
                  id="password"
                  type="password"
                  name="password"
                  value={data.password}
                  className={'w-full ' + (errors.password && 'error')}
                  autoComplete="current-password"
                  onChange={handleOnChange}
                  placeholder="Password"
                />
                <InputError message={errors.password} />
              </div>

              <button disabled={processing}>Sign in</button>

              <div className="grid">
                {canResetPassword && (
                  <Link
                    href={window.route('password.request')}
                    className="text-sm text-ocseo-jet-regular"
                  >
                    Reset Password
                  </Link>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </GuestLayout>
  );
}
